import { useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import React from 'react';

import { useAccountChange, useLogger, useNetworkChange, useNotifications, usePools, useBalances } from 'src/hooks';

import { Signer } from 'src/models';

import { useSelector, getSigner } from 'src/state';

const RPC_URLS = {
  1: 'https://mainnet.infura.io/v3/6c250930fbf642daa814ae38d81fd0d9',
  4: 'https://rinkeby.infura.io/v3/6c250930fbf642daa814ae38d81fd0d9',
};

const POLLING_INTERVAL = 12000;

const walletConnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

const metaMask = new InjectedConnector({ supportedChainIds: [1, 4] });

export function Middleware() {
  useLogger();
  usePools();
  useBalances();
  useAccountChange();
  useNetworkChange();
  useNotifications();

  const web3 = useWeb3React();

  const signer = useSelector(getSigner);

  React.useEffect(() => {
    if (signer === Signer.METAMASK) {
      web3.activate(metaMask);
      metaMask.getAccount();
    }

    if (signer === Signer.WALLET_CONNECT) {
      web3.activate(walletConnect);
      walletConnect.activate().then(w => {
        w.provider.wc._qrcodeModal.close();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer]);
  return <div />;
}
