import React from 'react';

import { ifTrue } from 'src/toolbelt';
import { useMedia } from 'src/hooks';

import { Video, ActionButton } from 'src/components';

import style from './Hero.module.scss';

export function Hero() {
  const isMobile = useMedia();

  return (
    <section className={style.container}>
      <block className={style.picture}>
        <div className={style.image} />
      </block>
      <content>
        <block>
          <div className={style.group}>
            <block className={style.buttons} style={ifTrue(Boolean(isMobile), { flexFlow: 'column' })}>
              <ActionButton
                kirobo
                label='Add Liquidity'
                link='https://app.uniswap.org/#/add/v2/ETH/0xB1191F691A355b43542Bea9B8847bc73e7Abb137'
                styles={{ width: 'auto', background: 'var(--linearKiroboNonTrans)' }}
              />
              <div className={ifTrue(Boolean(isMobile), style.vertical, style.horizontal)} />
              <ActionButton
                white
                label='KIRO/ETH Pool on Uniswap v2'
                link='https://app.uniswap.org/#/add/v2/ETH/0xB1191F691A355b43542Bea9B8847bc73e7Abb137'
              />
            </block>
            <div className={ifTrue(Boolean(isMobile), style['spacer-mobile'], style['spacer-desktop'])} />
            <div className={style.view}>
              <ActionButton
                label='View on Etherscan'
                link='https://etherscan.io/address/0x50e49a438267218a3fecc3754d7b6dfa0c492ac1#code'
                styles={{ width: 'auto', background: 'var(--linearDia)' }}
              />
            </div>
          </div>
        </block>
        <div className={style['blocks-space']} />
        <block className={style.video}>
          <Video />
        </block>
      </content>
    </section>
  );
}
