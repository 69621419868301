import { AnyAction, State } from 'src/models';

function logState(state: State, action: AnyAction, result: State) {
  console.groupCollapsed(
    '%c[ACTION]',
    'background-color: lemonchiffon;padding: 2px 8px; border-radius: 2px',
    action.type,
  );
  console.log(
    '%c[previous state]',

    'color: royalblue',
    state,
  );

  console.info('%caction', 'font-style: italic', action);
  console.log('%c[next state]', 'color: darkorchid', result);

  console.groupEnd();
}

export { logState };
