import React from 'react';

import { Actions, Dispatch, State } from 'src/models';
import { reducer } from './reducer';

import { INITIAL_STATE } from './initial';

const StateContext = React.createContext<State | undefined>(undefined);

const DispatchContext = React.createContext<Dispatch | undefined>(undefined);

function StateContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

const useAppState = () => {
  const context = React.useContext(StateContext);

  if (context === undefined) {
    throw new Error('useCountState must be used within a ContextProvider');
  }

  return context;
};

function useSelector<T>(fn: (state: State) => T) {
  const context = React.useContext(StateContext);

  if (context === undefined) {
    throw new Error('useCountState must be used within a ContextProvider');
  }

  return fn(context);
}

function useDispatch() {
  const context = React.useContext(DispatchContext);

  if (context === undefined) {
    throw new Error('useDispatch must be used within a ContextProvider');
  }

  return context;
}

export { StateContextProvider, useDispatch, useAppState, Actions, useSelector };
