import clsx from 'clsx';
import React from 'react';

import style from './Video.module.scss';

export function Video() {
  return (
    <container className={style.container}>
      <h4>Staking Step-by-Step Guide (KIRO)</h4>
      <object className={style.video}>
        <param
          name='movie'
          value='https://www.youtube.com/embed/6o2OVCjWqeI?html5=1&amp;rel=0&amp;hl=en_US&amp;version=3'
        />
        <param name='allowFullScreen' value='true' />
        <param name='allowscriptaccess' value='always' />
        <embed
          className={clsx(style.video, 'animate__animated animate__fadeIn')}
          src='https://www.youtube.com/embed/6o2OVCjWqeI?html5=1&amp;rel=0&amp;hl=en_US&amp;version=3'
          type='text/html'
        />
      </object>
    </container>
  );
}
