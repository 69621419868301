import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { getGenericAPY } from 'src/toolbelt/apy';

import { Actions } from 'src/models';
import { getIsAuth, setSelectedPool, useDispatch, useSelector, useAppState, setAPY } from 'src/state';

import { CardButton, Apy, Hero } from 'src/components';

import { RINKEBY_POOLS, MAINNET_POOLS } from 'src/constants/addresses';
import styles from './TitlePage.module.scss';

export function TitlePage() {
  const dispatch = useDispatch();

  const isAuthed = useSelector(getIsAuth);

  const chainId = useWeb3React().chainId;

  const { apy } = useAppState();

  const fetchGenericAPY = async () => {
    const generalAPY = await getGenericAPY();

    dispatch(setAPY(generalAPY));
  };

  useEffect(() => {
    fetchGenericAPY();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pools = chainId === 4 ? RINKEBY_POOLS : MAINNET_POOLS;

  const handleOnClick = (poolIndex: number) => () => {
    dispatch(setSelectedPool(pools[poolIndex]));
    dispatch({ type: Actions.INIT_CONTRACTS });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.welcome}>Welcome to Kirobo staking!</h1>
        <h2 className={styles.title}>Connect account and enter the pool</h2>
        <div className={styles.buttons}>
          <CardButton disabled={!isAuthed} onClick={handleOnClick(0)} token='ETH' />
        </div>
        <Apy apy={apy ? apy * 100 : 0} />
      </div>
      <Hero />
    </div>
  );
}
