import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { appIsAuthed, getIsAuth, useAppState, useDispatch, useSelector } from 'src/state';

import { useBalances } from './useBalances';

function useAccountChange() {
  const dispatch = useDispatch();

  const { account } = useWeb3React();

  const [prevAccount, setPrevAccount] = React.useState(account);

  const { uniPoolContract, lpTokenContract } = useAppState();

  const { getAllBalances } = useBalances();

  const isAuth = useSelector(getIsAuth);

  React.useEffect(() => {
    if (account && !isAuth) {
      dispatch(appIsAuthed(true));
    } else if (!account && isAuth) {
      dispatch(appIsAuthed());
      // TODO: wipe
    }

    if (account && account !== prevAccount && uniPoolContract && lpTokenContract) {
      getAllBalances(uniPoolContract, lpTokenContract);
      setPrevAccount(account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, uniPoolContract, lpTokenContract]);
}

export { useAccountChange };
