import clsx from 'clsx';
import React from 'react';
import { IconClose } from 'src/assets';
import { useDispatch, useAppState, setShowWarning } from 'src/state';
import Config from 'src/config';

import style from './YellowStrip.module.scss';

export function YellowStrip() {
  const dispatch = useDispatch();

  const [showWarning, setShowWarning] = React.useState(true);

  if (!showWarning) return <></>;

  return (
    <div className={clsx(style.container, 'animate__animated animate__fadeIn')}>
      <p className={style.message}>
        {Config.newStripText[0]}
        <a href={Config.newStripLink} target='_blank' rel='noopener noreferrer' className={style.link}>
          {Config.newStripText[1]}
        </a>{' '}
        {Config.newStripText[2]}
      </p>
      <button
        className={style.icon}
        onClick={() => {
          setShowWarning(false);
        }}
      >
        <IconClose />
      </button>
    </div>
  );
}
