import { Type } from '.';

export function makePromise<T>(func: unknown, ...params: unknown[]): Promise<T> {
  return new Promise((resolve, reject) => {
    let callBack = (err: unknown, res: unknown) => (err ? reject(err) : resolve(Type<T>(res)));
    // @ts-ignore
    func(...params, callBack);
  });
}

export const makePromiseMS = (timeout: number, func: unknown, ...params: unknown[]) => {
  return new Promise((resolve, reject) => {
    let callBack = (err: unknown, res: unknown) => (err ? reject(err) : resolve(res));
    // @ts-ignore
    func(...params, callBack);
    setTimeout(() => {
      reject('timeout');
    }, timeout);
  });
};
