import clsx from 'clsx';
import React from 'react';

import { useMedia } from 'src/hooks';
import { ifTrue } from 'src/toolbelt';

import { useWeb3React } from '@web3-react/core';

import { getIsTitlePage, useSelector, setSelectedPool, useDispatch } from 'src/state';

import { Logo } from 'src/assets';
import { AccountSelector } from '..';

import style from './Navbar.module.scss';

export function Navbar() {
  const isMobile = useMedia();

  const chainId = useWeb3React().chainId;

  const isDisabled = chainId && chainId !== 1 && chainId !== 4;

  const dispatch = useDispatch();

  const isTitlePage = useSelector(getIsTitlePage);

  const handleLogoOnClick = () => dispatch(setSelectedPool());

  const renderRight = () => <AccountSelector />;

  function renderRightConnect() {
    if (isDisabled) return <></>;

    return renderRight();
  }

  return (
    <nav
      className={clsx(
        style.container,
        ifTrue(isMobile, style.mobile, style.desktop),
        ifTrue(!isTitlePage, style.shadow),
      )}
    >
      <div className={style.logo} onClick={handleLogoOnClick}>
        <Logo />
      </div>
      <div className={style.row}>{renderRightConnect()}</div>
    </nav>
  );
}
