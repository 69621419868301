import BN from 'bn.js';

import { setPendingTx, setPendingTxStatus, useAppState, useDispatch, setStakingStage } from 'src/state';
import { StakingStage } from 'src/models';

import { useWeb3React } from '@web3-react/core';

import { sendMessage } from 'src/toolbelt';

function useTransaction() {
  const {
    uniPoolContract,
    lpTokenContract,
    balance,
    stakedBalance,
    selectedPool,
    rewardBalance,
    maxAllowed,
  } = useAppState();

  const dispatch = useDispatch();

  const chainId = useWeb3React().chainId;

  const account = useWeb3React().account;

  function dispatchPending(hash: string | undefined, txType: string, token: string, value: BN) {
    if (hash)
      dispatch(
        setPendingTx({
          hash,
          explorerUrl: `https://${chainId === 4 ? 'rinkeby.' : ''}etherscan.io/tx/${hash}`,
          txType,
          token,
          value,
          status: 'pending',
        }),
      );
  }

  async function approve(value: string) {
    if (account && selectedPool && lpTokenContract && uniPoolContract)
      try {
        const tokenName = selectedPool.lpTokenName;

        const allowanceEthers = await lpTokenContract.allowance(account, selectedPool?.unipoolAddress);

        const allowance = new BN(allowanceEthers.toString());

        const amount = BN.min(new BN(value), balance);

        if (allowance.lt(amount)) {
          dispatch(setStakingStage(StakingStage.APPROVING));

          const tx = await lpTokenContract.approve(uniPoolContract.address, amount.toString());

          dispatchPending(tx.hash, 'Approving', `${tokenName} UNI LP tokens`, amount);
          await tx.wait(1);
          dispatch(setPendingTxStatus('confirmed'));
          sendMessage('Confirmed', 'Approve transaction confirmed');
          dispatch(setStakingStage(StakingStage.APPROVED));
        } else {
          // TODO: why it is approved, if not following the rule?
          dispatch(setStakingStage(StakingStage.APPROVED));
        }
      } catch (err) {
        console.log(`Error approving ${err}`);
      }
  }

  async function stake(value: string) {
    if (selectedPool && lpTokenContract && uniPoolContract && maxAllowed)
      try {
        const tokenName = selectedPool?.lpTokenName;

        const amount = BN.min(new BN(value), balance);

        const minAmount = BN.min(amount, maxAllowed as BN);

        dispatch(setStakingStage(StakingStage.STAKING));

        const tx = await uniPoolContract.stake(minAmount.toString());

        dispatchPending(tx.hash, 'Staking', `${tokenName} UNI LP tokens`, amount);

        await tx.wait(1);
        dispatch(setPendingTxStatus('confirmed'));
        dispatch(setStakingStage(StakingStage.COMPLETE));
        sendMessage('Confirmed', 'Staking transaction confirmed');
      } catch (err) {
        // console.log(`Error staking: ${JSON.stringify(err)}`);
        dispatch(setPendingTxStatus('failed'));
        // change the statu back and keep the trx, so we could re-try
        dispatch(setStakingStage(StakingStage.APPROVED));
        // dispatch(setPendingTransaction(undefined));
      }
  }

  async function withdraw(value: string) {
    if (uniPoolContract)
      try {
        const amount = BN.min(new BN(value), stakedBalance);

        const tx = await uniPoolContract.withdraw(amount.toString());

        const tokenName = selectedPool?.lpTokenName;

        dispatchPending(tx.hash, 'Withdrawing', `${tokenName} UNI LP tokens`, amount);

        await tx.wait(1);
        sendMessage('Confirmed', 'Withdraw transaction confirmed');
        dispatch(setPendingTxStatus('confirmed'));
      } catch (err) {
        // console.log(`Error withdrawing: ${err}`);
        dispatch(setPendingTxStatus('failed'));
        dispatch(setPendingTx(undefined));
      }
  }

  async function claim() {
    if (uniPoolContract)
      try {
        const tx = await uniPoolContract.getReward();

        dispatchPending(tx.hash, 'Claiming', 'KIRO tokens', rewardBalance);
        await tx.wait(1);
        dispatch(setPendingTxStatus('confirmed'));
        sendMessage('Confirmed', 'Claim rewards transaction confirmed');
      } catch (err) {
        // console.log(`Error claiming: ${err}`);
        dispatch(setPendingTxStatus('failed'));
        dispatch(setPendingTx(undefined));
      }
  }

  return { stake, withdraw, claim, approve };
}

export { useTransaction };
