import clsx from 'clsx';
import React from 'react';
import { useMedia } from 'src/hooks';
import { ifTrue, ifTrueAlt, ifTrueFn } from 'src/toolbelt';

import style from './ActionButton.module.scss';

interface Props {
  kirobo?: boolean;
  white?: boolean;
  onClick?: () => void;
  label: string;
  disabled?: boolean;
  styles?: React.CSSProperties;
  link?: string;
}

export function ActionButton({ white, link, styles, kirobo, disabled, onClick, label }: Props) {
  const isMobile = useMedia();

  const handleOnClick = () => onClick && ifTrueFn(!disabled, onClick);

  const classes = clsx(
    style.container,
    ifTrueAlt(isMobile, style.mobile, style.desktop),
    ifTrue(!!kirobo, style.kirobo),
    ifTrue(!!white, style.white),
  );

  const renderLabel = () => <p className={style.label}>{label}</p>;

  if (Boolean(link))
    return (
      <a className={classes} href={link} target='_blank' rel='noopener noreferrer' style={styles}>
        {renderLabel()}
      </a>
    );

  return (
    <button disabled={disabled} className={classes} onClick={handleOnClick} style={styles}>
      {renderLabel()}
    </button>
  );
}
