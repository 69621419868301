const LINKS: Record<string, string>[] = [
  { link: 'https://kirobo.io/support', label: 'Learn More' },
  { link: 'https://kirobo.io/support/knowledge-base/terms-of-service', label: 'Terms of Service' },
  { link: 'https://kirobo.io/support/knowledge-base/privacy-policy', label: 'Privacy Policy' },
  {
    link: 'https://github.com/g0-group/Audits/blob/master/StakingKiroboSep2020.pdf',
    label: 'Audit Report',
  },
];

export { LINKS };
