import React from 'react';

import { useMedia } from 'src/hooks';
import { copied } from 'src/toolbelt';
import { Avatar } from '../Avatar';
import { Tooltip } from '../Tooltip';

import style from './Account.module.scss';

interface Props {
  account: string;
}

export function Account({ account }: Props) {
  const isMobile = useMedia();

  function handleOnCopy() {
    navigator.clipboard.writeText(account);
    copied();
  }

  if (isMobile) return <></>;

  return (
    <div className={style.container}>
      <Tooltip message='ETH address'>
        <div className={style['left-block']} onClick={handleOnCopy}>
          <div className={style.label}>Address</div>
          <p className={style.address}>{account.slice(0, 5) + '...' + account.substr(-3)}</p>
        </div>
      </Tooltip>
      <Avatar address={account} scale={1.3} radius={4} />
    </div>
  );
}
