import { not } from 'ramda';
import React from 'react';
import { ifTrue, isNilOrEmpty } from 'src/toolbelt';

const getVertical = (min: string, max: string): React.CSSProperties => ({
  height: ifTrue(not(isNilOrEmpty(max)), max, '100%'),
  minHeight: ifTrue(not(isNilOrEmpty(min)), min, '1rem'),
});

const getHorizontal = (min: string, max: string): React.CSSProperties => ({
  width: ifTrue(not(isNilOrEmpty(max)), max, '100%'),
  minWidth: ifTrue(not(isNilOrEmpty(min)), min, '1rem'),
});

interface Props {
  horizontal?: boolean;
  min?: string;
  max?: string;
}

export function FlexSpacer({ horizontal, min, max }: Props) {
  return (
    <div style={ifTrue(!!horizontal, getHorizontal(min || '0', max || '0'), getVertical(min || '0', max || '0'))} />
  );
}
