enum Actions {
  AUTHED = 'appIsAuthed',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  GET_NETWORK = 'getNetwork',
  GET_BALANCE = 'getBalance',
  INIT_CONTRACTS = 'initContracts',
  GET_STAKED_BALANCE = 'getStakedBalance',
  GET_REWARD_BALANCE = 'getRewardBalance',
  GET_BALANCES = 'getBalances',
  APPROVE = 'approve',
  STAKE = 'stake',
  WITHDRAW = 'withdraw',
  CLAIM = 'claim',
  ALLOWANCE = 'allowance',
  APY = 'apy',
  SET_APY = 'setAPY',
  SET_WEEKLY_REWARD = 'setWeeklyReward',
  GENERIC_APY = 'generic_apy',
  SET_SELECTED_POOL = 'setSelectedPool',
  SET_LP_TOKEN_CONTRACT = 'setLpTokenContract',
  SET_UNI_POOL_CONTRACT = 'setUniPoolContract',
  SET_REWARD_BALANCE = 'setRewardBalance',
  SET_STAKED_BALANCE = 'setStakedBalance',
  SET_TOKEN_BALANCE = 'setTokenBalance',
  SET_MAX_ALLOWED = 'setMaxAllowed',
  SET_SIGNER = 'setSigner',
  SET_PENDING_TX = 'setPendingTx',
  SET_PENDING_TX_STATUS = 'setPendingTxStatus',
  SET_STAKING_STAGE = 'setStakingStage',
  WIPE_STATE = 'wipeState',
  CLEAR_CONTRACT_DATA = 'clearContractData',
  CLEAR_ERRORS = 'clearErrors',
  ADD_ERROR = 'addError',
  SET_SHOW_WARNING = 'setShowWarning',
}

export { Actions };
