import BN from 'bn.js';
import { State, StakingStage } from 'src/models';

const INITIAL_STATE: State = {
  appError: '',
  isAuth: false,
  signer: undefined,
  selectedPool: undefined,
  errors: [],
  apy: undefined,
  maxApy: undefined,
  weeklyReward: undefined,
  stakingStage: StakingStage.INIT,
  showWarning: true,
  balance: new BN(0), // UNI LP tokens balance in the wallet
  stakedBalance: new BN(0), // UNI LP tokens staked in the Unipool
  rewardBalance: new BN(0), // Balance of the earned reward token
  maxAllowed: undefined,
  pending: undefined,
  lpTokenAllowance: new BN(0),
  lpTokenContract: undefined,
  uniPoolContract: undefined,
};

export { INITIAL_STATE };
