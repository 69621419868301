import React, { useState } from 'react';
import { fromWei } from 'web3-utils';
import BN from 'bn.js';
import { contains, last, split } from 'ramda';
import { compose } from 'ramda';
import utils from 'web3-utils';

import { useMedia, useTransaction } from 'src/hooks';
import { ifTrue, ifTrueFn, kiroboRound } from 'src/toolbelt';

import { useAppState, getIsAuth, useSelector } from 'src/state';

import { Balance, Instructions, Spacer, Errors, ActionButton, Input, FlexSpacer } from '..';

interface Props {
  noErrors: boolean;
}

export function WithdrawTab({ noErrors }: Props): JSX.Element {
  const isMobile = useMedia();

  const isAuthed = useSelector(getIsAuth);

  const { withdraw } = useTransaction();

  const { balance, stakedBalance, pending } = useAppState();

  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  const [quantity, setQuantity] = useState('');

  const [clear, setClear] = useState(false);

  const handleOnClick = () => {
    withdraw(utils.toWei(quantity, 'ether'));
  };

  React.useEffect(() => {
    if (isAuthed && quantity && quantity !== '0' && buttonIsDisabled) setButtonIsDisabled(false);
    else if (!quantity && !buttonIsDisabled) setButtonIsDisabled(true);

    if (pending?.status === 'pending') setButtonIsDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonIsDisabled, isAuthed, quantity]);

  React.useEffect(() => {
    if (pending?.status === 'pending') {
      setButtonIsDisabled(true);
      setClear(true);
    }

    if (pending?.status === 'confirmed' && clear) {
      setQuantity('');
      setClear(false);
    }
  }, [clear, pending]);

  const makeAmount = (value: BN) => kiroboRound(fromWei(value.toString()));

  const totalBalance = stakedBalance.isZero() ? balance : balance.add(stakedBalance);

  const renderErrors = () => (
    <>
      <Spacer vertical={1} />
      <Errors />
    </>
  );

  function handleInput(value: string) {
    let newValue = value;

    if (contains('.', newValue) && compose(last, split(''))(newValue) !== '0') {
      if (parseFloat(newValue)) newValue = kiroboRound(newValue);
    }

    setQuantity(newValue);
  }

  return (
    <>
      <div style={{ height: '6.8rem' }}>
        <Instructions instruction='Select LP tokens for withdrawal' />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          margin: ifTrue(isMobile, '1rem 0', '2rem 0'),
        }}
      >
        <Balance align={ifTrue(isMobile, 'left')} label='Your Pool Balance' amount={makeAmount(totalBalance)} />
        <Balance
          align={ifTrue(isMobile, 'right')}
          label='Staked (out of the Pool Balance)'
          amount={makeAmount(stakedBalance)}
        />
      </div>
      <Input
        withMax
        value={quantity}
        max={stakedBalance}
        onChange={handleInput}
        disabled={pending?.status === 'pending'}
        maxButtonDisabled={pending?.status === 'pending'}
      />
      {ifTrueFn(!noErrors, renderErrors)}
      <FlexSpacer max='100%' min='1rem' />
      <ActionButton disabled={buttonIsDisabled} label='Withdraw' onClick={handleOnClick} />
    </>
  );
}
