import React from 'react';
import { useWeb3React } from '@web3-react/core';

import { copied } from 'src/toolbelt';

import clsx from 'clsx';

import style from './AccountMobile.module.scss';
import { Avatar } from '..';

export function AccountMobile() {
  const account = useWeb3React()?.account;

  // eslint-disable-next-line no-console
  console.log(account);

  function handleOnCopy() {
    if (account) {
      navigator.clipboard.writeText(account);
      copied();
    }
  }

  if (!account) return <></>;

  return (
    <div className={clsx(style.container, 'animate__animated animate__flipInX')} onClick={handleOnCopy}>
      <div className={style.text}>
        <p className={style.label}>Address</p>
        <p className={style.address}>{account}</p>
      </div>
      <div className={style.avatar}>
        <Avatar address={account} scale={1} radius={4} />
      </div>
    </div>
  );
}
