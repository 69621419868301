import React from 'react';
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';

interface Props {
  placement?: Placement;
  message: string;
  children: JSX.Element;
}

export function Tooltip({ placement = 'bottom', message, children }: Props) {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <TooltipBS id={`tooltip-${placement}`}>
          <p style={{ fontSize: '1.4rem' }}>{message}</p>
        </TooltipBS>
      }
    >
      {children}
    </OverlayTrigger>
  );
}
