import clsx from 'clsx';
import React from 'react';
import { compose } from 'ramda';
import { isNilOrEmpty, makeStringAmount } from 'src/toolbelt';

import style from './Apy.module.scss';

interface Props {
  apy: number | undefined;
}

const makeCurrentApy = (apy: number) =>
  compose(makeStringAmount({ min: 2, max: 2 }), parseFloat)(String(apy || 0)) + ' %';

export function Apy({ apy }: Props) {
  if (isNilOrEmpty(apy) || apy === 0) return <div style={{ height: '2.5rem', width: '.1rem' }}></div>;

  return (
    <div className={style.container}>
      <p className={clsx(style.apy, 'animate__animated  animate__slideInDown')}>
        APY: <span>{apy ? makeCurrentApy(apy) : 0}</span>
      </p>
    </div>
  );
}
