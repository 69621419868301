import { UnknownRecord, Error, PendingTransaction } from 'src/models';
import BN from 'bn.js';
import { Maybe } from 'src/toolbelt';

import { TokenContract } from './crypto';

interface StakingPool {
  unipoolAddress: string;
  lpTokenAddress: string;
  lpTokenName: string;
}

enum StakingStage {
  INIT = 'init',
  APPROVING = 'approving',
  APPROVED = 'approved',
  STAKING = 'staking',
  COMPLETE = 'complete',
}

enum Signer {
  METAMASK = 'metamask',
  WALLET_CONNECT = 'walletConnect',
}

interface State extends UnknownRecord {
  appError: string;
  apy: Maybe<number>;
  balance: BN;
  errors: Error[];
  isAuth: boolean;
  lpTokenAllowance: BN;
  lpTokenContract: Maybe<TokenContract>;
  maxAllowed: Maybe<BN>;
  maxApy: Maybe<number>;
  pending: Maybe<PendingTransaction>;
  rewardBalance: BN;
  selectedPool: Maybe<StakingPool>;
  showWarning: boolean;
  signer: Maybe<Signer>;
  stakedBalance: BN;
  stakingStage: StakingStage;
  uniPoolContract: Maybe<TokenContract>;
  weeklyReward: Maybe<number>;
}

export type { State, StakingPool };

export { StakingStage, Signer };
