import { compose } from 'ramda';
import React from 'react';

import { setSigner, useDispatch } from 'src/state';

import { Signer } from 'src/models';

import { CardButton } from '..';

import style from './WalletSelector.module.scss';

interface Props {
  onClose: () => void;
}

export function WalletSelector({ onClose }: Props) {
  const dispatch = useDispatch();

  const handleSelect = (id: Signer) => () => {
    dispatch(setSigner(id));
    onClose();
  };

  const metamaskButtonContent = () => (
    <>
      <img src='/images/logo_metamask.svg' alt='MetaMask' />
      <h2>Use MetaMask</h2>
    </>
  );

  const walletConnectButtonContent = () => (
    <>
      <img src='/images/logo_wallet_connect.png' alt='MetaMask' />
      <h2>Use WalletConnect</h2>
    </>
  );

  const renderConnect = (content: JSX.Element): JSX.Element => <div className={style.button}>{content}</div>;

  return (
    <container className={style.container}>
      <h1>Please, select wallet:</h1>
      <block>
        <CardButton
          disabled={false}
          onClick={handleSelect(Signer.METAMASK)}
          content={compose(renderConnect, metamaskButtonContent)()}
        />
        <CardButton
          disabled={false}
          onClick={handleSelect(Signer.WALLET_CONNECT)}
          content={compose(renderConnect, walletConnectButtonContent)()}
        />
      </block>
    </container>
  );
}
