import { useWeb3React } from '@web3-react/core';
import React from 'react';

import { lg } from 'src/toolbelt';

import { useDispatch, wipeState } from 'src/state';

function useNetworkChange() {
  const dispatch = useDispatch();

  const { chainId } = useWeb3React();

  const [prevChainId, setPrevChainId] = React.useState(chainId);

  React.useEffect(() => {
    if (prevChainId && chainId !== prevChainId) {
      // TODO: wipe state
      lg('ready to wipe state;', prevChainId, chainId);
      dispatch(wipeState());
    }

    setPrevChainId(chainId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, prevChainId]);
}

export { useNetworkChange };
