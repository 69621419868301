import clsx from 'clsx';
import React from 'react';

import { setSelectedPool, useDispatch } from 'src/state';

import style from './BackButton.module.scss';

export function BackButton() {
  const dispatch = useDispatch();

  const handleOnClick = () => dispatch(setSelectedPool());

  return (
    <div className={clsx(style.container, 'animate__animated	animate__fadeIn')} onClick={handleOnClick}>
      <button className={style.circle}>
        <div className={style.triangle} />
      </button>
      <p className={style.label}>BACK</p>
    </div>
  );
}
