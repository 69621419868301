import BN from 'bn.js';
import { compose } from 'ramda';
import { fromWei } from 'web3-utils';
import React, { CSSProperties } from 'react';

import { useMedia, useTransaction } from 'src/hooks';
import { ifTrue, ifTrueAlt, makeStringAmount, kiroboRound } from 'src/toolbelt';

import { useAppState } from 'src/state';

import { ActionButton, Balance, FlexSpacer, Instructions } from '..';

export function ClaimTab() {
  const { claim } = useTransaction();

  const isMobile = useMedia();

  const { rewardBalance, apy, pending, weeklyReward } = useAppState();

  const buttonIsDisabled = rewardBalance.isZero() || pending?.status === 'pending';

  const handleOnClick = () => {
    claim();
  };

  const makeCurrentApy = () =>
    compose(makeStringAmount({ min: 2, max: 2 }), parseFloat)(String(apy ? apy * 100 : 0)) + ' %';

  const makeCurrentWeeklyReward = () => kiroboRound(weeklyReward || 0);

  const makeAmount = (value: BN) => kiroboRound(fromWei(value.toString()));

  const makeCurrentReward = () => makeAmount(rewardBalance) + ' KIRO';

  const getNumberStyles = (): CSSProperties | undefined => ifTrue(isMobile, { textAlign: 'center' });

  return (
    <>
      <div style={{ height: '6.8rem' }}>
        <Instructions instruction='Claim your earned KIRO rewards' />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: ifTrue(isMobile, '1rem 0', '2rem 0'),
        }}
      >
        <Balance
          label='Current APY'
          amount={makeCurrentApy()}
          numberStyles={getNumberStyles()}
          labelStyles={ifTrue(isMobile, { flex: 'inherit', textAlign: 'center' })}
        />
        <Balance
          label='My Current Rate (KIRO/Week)'
          amount={makeCurrentWeeklyReward()}
          numberStyles={getNumberStyles()}
          labelStyles={ifTrue(isMobile, { textAlign: 'center' })}
        />
      </div>
      <Balance label='My Current Rewards' amount={makeCurrentReward()} numberStyles={getNumberStyles()} />
      <FlexSpacer max={ifTrueAlt(isMobile, '2.4rem', '2.5rem')} min='2rem' />
      <ActionButton
        disabled={buttonIsDisabled || pending?.status === 'pending'}
        label='Claim Rewards'
        onClick={handleOnClick}
      />
    </>
  );
}
