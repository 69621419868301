import clsx from 'clsx';
import React from 'react';

import { ifTrueAlt, isNilOrEmpty } from 'src/toolbelt';

import style from './Spacer.module.scss';

interface Props {
  vertical?: number;
  horizontal?: number;
}

export function Spacer({ vertical, horizontal }: Props) {
  if (isNilOrEmpty(vertical) && isNilOrEmpty(horizontal))
    throw new Error('Provide either vertical or horizontal prop.');

  const makeDimension = (dim: number) => `${dim}rem`;

  return (
    <div
      className={clsx(style.spacer, ifTrueAlt(!!vertical, style.vertical, style.horizontal))}
      style={ifTrueAlt(
        !!vertical,
        { height: makeDimension(vertical as number) },
        { width: makeDimension(horizontal as number) },
      )}
    />
  );
}
