// - KIRO token: 0xDc7988DC2fA23EA82d73B21B63Da5B905Fb52074
// - KIRO-ETH UNI V2 pair: 0xd0fd23E6924a7A34d34BC6ec6b97fadD80BE255F
// - Unipool (KIRO adapted): 0x078429D6e15B8e2F850783BAD3F2853c07cd9Af6

import { StakingPool } from 'src/models';
import Config from 'src/config';

export const KIRO = {
  mainnet: '0xb1191f691a355b43542bea9b8847bc73e7abb137',
  rinkeby: '0xDc7988DC2fA23EA82d73B21B63Da5B905Fb52074',
};

export const RINKEBY_POOLS: StakingPool[] = [
  {
    // unipoolAddress: '0x911F43c5f910D44d968200bEa58249c93750f926',
    // unipoolAddress: '0xC8e97A428f8559f07706ab84821E75aA6E5f6492',
    // unipoolAddress: '0x739aeB48f4d7f4Bf2d05a3d22fd4B2db0E16E584',
    // unipoolAddress: '0xb50A5FBD65397763B71AB5467B581b64873dA177',
    // unipoolAddress: '0x58502386058c134ff92A7C3Ba2636879191EbA2a',
    // unipoolAddress: '0x3F913bf97E5Fff85638D817dc1E14cFb95D79906',
    // unipoolAddress: '0x967da6ed2801009cDF269977886378274f48Ee74',
    unipoolAddress: Config.unipoolRinkeby || '0xB7265c28ae376Bf20Fc998eb85A1B75d564B9DaB',
    lpTokenAddress: '0xd0fd23E6924a7A34d34BC6ec6b97fadD80BE255F',
    lpTokenName: 'KIRO-ETH',
  },
  {
    unipoolAddress: '0x00',
    lpTokenAddress: '0x00',
    lpTokenName: 'KIRO-USDT',
  },
];

export const MAINNET_POOLS: StakingPool[] = [
  {
    unipoolAddress: Config.unipoolMainnet || '0x50E49A438267218a3FEcC3754d7b6dFA0C492AC1',
    lpTokenAddress: '0x5cd136e8197be513b06d39730dc674b1e0f6b7da',
    lpTokenName: 'KIRO-ETH',
  },
  {
    unipoolAddress: '0x00',
    lpTokenAddress: '0x00',
    lpTokenName: 'KIRO-USDT',
  },
];
