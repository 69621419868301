import { AnyAction, State } from 'src/models';

import { logState } from 'src/toolbelt';
import { MAP } from './map';

function reducer(state: State, action: AnyAction) {
  // check if function exists. if yes -> run, if no -> return current state
  const fn = MAP.get(action.type);

  // needed to log it all at once before return
  let result = state;

  if (fn) result = fn(state, action);

  logState(state, action, result);

  return result;
}

export { reducer };
