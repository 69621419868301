import React from 'react';

export function IconNetwork() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
      clipRule='evenodd'
      viewBox='0 0 22 20'
    >
      <path fill='none' fillRule='nonzero' stroke='' strokeWidth='2' d='M21 10h-4l-3 9L8 1l-3 9H1'></path>
    </svg>
  );
}
