import * as ethers from 'ethers';
import { AsyncSendable } from 'ethers/providers';

import { Type } from '.';

import { WindowExtended, ProviderExtended, PromisedActionFn } from 'src/models';

const connectEthProvider = (provider: unknown) => new ethers.providers.Web3Provider(provider as AsyncSendable);

const windowExt = () => Type<WindowExtended>(window);

const ethereum = () => windowExt().ethereum as ProviderExtended;

const send = () => ethereum().sendAsync as PromisedActionFn;

export { connectEthProvider, windowExt, ethereum, send };
