import React from 'react';
import clsx from 'clsx';
import { compose, join, map } from 'ramda';

import { Error } from 'src/models';
import { useAppState } from 'src/state';

import style from './Error.module.scss';
import { ifTrue } from 'src/toolbelt';
import { useMedia } from 'src/hooks';

export function Errors() {
  const isMobile = useMedia();

  const { errors } = useAppState();

  const makeErrors = () =>
    compose(
      join('. '),
      map((err: Error) => err.message),
    )(errors);

  return (
    <div className={clsx(style.container, ifTrue(isMobile, style.mobile))}>
      <p className={clsx(style.message, 'animate__animated animate__fadeIn')}>{makeErrors()}</p>
    </div>
  );
}
