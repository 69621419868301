import { Message, Notification } from 'element-react/next';
import { is } from 'ramda';

function notify(message: string): void {
  if (message && is(String, message))
    Message({
      message,
      type: 'info',
    });

  return;
}

const copied = () => notify('Copied');

const sendMessage = (title: string, message: string) =>
  Notification.info({
    message,
    title,
    type: 'info',
    offset: 55,
    duration: 4500,
  });

const sendWarning = (title: string, message: string) =>
  Notification.info({
    message,
    title,
    type: 'warning',
    offset: 55,
    duration: 4500,
  });

export { notify, copied, sendMessage, sendWarning };
