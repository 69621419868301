import clsx from 'clsx';
import React from 'react';

import { useMedia } from 'src/hooks';
import { ifTrue } from 'src/toolbelt';

import { getIsTitlePage, useSelector } from 'src/state';

import style from './KiroboServiceButton.module.scss';

export function KiroboServiceButton() {
  const isMobile = useMedia();

  const isTitlePage = useSelector(getIsTitlePage);

  const getStyles = () =>
    clsx(style.container, ifTrue(!isMobile, style.desktop), ifTrue(isMobile && isTitlePage, style['mobile-title']));

  return (
    <a href='https://undo.kirobo.me' target='_blank' rel='noopener noreferrer' className={getStyles()}>
      <div className={style.center}>
        <p className={style.label}>Use Kirobo Service</p>
      </div>
    </a>
  );
}
