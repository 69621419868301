import React from 'react';
import { ifTrueAlt } from 'src/toolbelt';

import { WithdrawTab, StakeTab, ClaimTab } from '.';

interface Props {
  tab: string;
  current?: boolean;
  dialogMode?: boolean;
}

export function Tabs({ dialogMode, tab, current }: Props) {
  const TABS: Record<string, JSX.Element> = {
    Withdraw: <WithdrawTab noErrors={ifTrueAlt(!!dialogMode, false, !current)} />,
    Stake: <StakeTab noErrors={ifTrueAlt(!!dialogMode, false, !current)} />,
    'Claim Rewards': <ClaimTab />,
  };

  return TABS[tab];
}
