import clsx from 'clsx';
import { assoc } from 'ramda';
import React, { CSSProperties } from 'react';

import { ifTrueAlt } from 'src/toolbelt';

import style from './MainButton.module.scss';

interface Props {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  small?: boolean;
  styles?: CSSProperties;
}

export function MainButton({ disabled, styles, onClick, label, small }: Props) {
  const makeStyles = () => ifTrueAlt(!!small, assoc('minHeight', '3rem', styles), styles);

  return (
    <button
      disabled={disabled}
      className={clsx(style.container, 'animate__animated animate__pulse')}
      onClick={() => onClick()}
      style={makeStyles()}
    >
      <p className={style.label}>{label}</p>
    </button>
  );
}
