import { fromWei, toWei } from 'web3-utils';
import { contains, last, split } from 'ramda';
import React, { useEffect } from 'react';
import utils from 'web3-utils';
import BN from 'bn.js';
import { compose } from 'redux';

import { useMedia, useTransaction } from 'src/hooks';
import { ifTrue, ifTrueFn, kiroboRound } from 'src/toolbelt';

import { useAppState, setStakingStage, useDispatch } from 'src/state';
import { StakingStage } from 'src/models';

import { Errors, ActionButton, Input, Instructions, Spacer, Balance, FlexSpacer } from '..';

interface Props {
  noErrors: boolean;
}

export function StakeTab({ noErrors }: Props) {
  const dispatch = useDispatch();

  const isMobile = useMedia();

  const { stake, approve } = useTransaction();
  // const isAuthed = useSelector(getAppIsAuth);

  const [stakingButtonIsDisabled, setStakingButtonIsDisabled] = React.useState(true);

  const [approveButtonIsDisabled, setApproveButtonIsDisabled] = React.useState(true);

  // const balance = useSelector(getBalance);
  const { balance, stakedBalance, stakingStage, maxAllowed, pending } = useAppState();

  // const stakedBalance = useSelector(getStakedBalance);

  const [quantity, setQuantity] = React.useState('');

  const [inputDisabled, setInputDisabled] = React.useState(false);

  useEffect(() => {
    setButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakingStage, quantity]);

  function disableBothButtons() {
    setApproveButtonIsDisabled(true);
    setStakingButtonIsDisabled(true);
  }

  type DisableIfNoQuantityProps =
    | undefined
    | {
        approve?: true;
        stake?: true;
      };

  function disableIfNoQuantity(enable: DisableIfNoQuantityProps) {
    if (!quantity || quantity === '0') disableBothButtons();

    if (quantity && quantity !== '0') {
      if (enable?.approve) setApproveButtonIsDisabled(false);

      if (enable?.stake) setStakingButtonIsDisabled(false);
    }
  }

  function setButtons() {
    switch (stakingStage) {
      case StakingStage.INIT:
        disableIfNoQuantity({ approve: true });
        break;
      case StakingStage.APPROVING:
        disableBothButtons();
        setInputDisabled(true);
        break;
      case StakingStage.APPROVED:
        setApproveButtonIsDisabled(true);
        setStakingButtonIsDisabled(false);
        setInputDisabled(true);
        break;
      case StakingStage.STAKING:
        disableBothButtons();
        break;
      case StakingStage.COMPLETE:
        setApproveButtonIsDisabled(quantity === '');
        setStakingButtonIsDisabled(true);
        setQuantity('');
        setInputDisabled(false);
        dispatch(setStakingStage(StakingStage.INIT));
        break;
    }
  }

  const stakeLimit = maxAllowed ? BN.min(balance, maxAllowed) : new BN(0);

  const stakeLimitNorm = utils.fromWei(stakeLimit);

  const additionalStake = maxAllowed ? maxAllowed.sub(balance) : new BN(0);

  const handleStakeClick = () => {
    // TODO: here we use stake(), instead of dispatch

    // dispatch({ type: Actions.STAKE, payload: utils.toWei(quantity.toString()) });
    stake(utils.toWei(quantity.toString()));
  };

  const handleApproveClick = () => {
    // dispatch({ type: Actions.APPROVE, payload: utils.toWei(quantity.toString()) });
    approve(utils.toWei(quantity.toString()));
  };

  const makeAmount = (value: BN) => kiroboRound(fromWei(value.toString()));

  const totalBalance = stakedBalance.isZero() ? balance : balance.add(stakedBalance);

  const renderErrors = () => (
    <>
      <Spacer vertical={1} />
      <Errors />
    </>
  );

  const makeExtraStake = () =>
    additionalStake.gtn(0)
      ? `You can stake up to ${kiroboRound(
          utils.fromWei(additionalStake),
        )} LP tokens by adding liquidity to the Uniswap KIRO-ETH pool.`
      : '';

  const makeAdditional = () =>
    stakeLimit.gtn(0) ? `You can stake additional ${kiroboRound(stakeLimitNorm)} LP tokens.` : '';

  const makeInstruction = () => `Choose KIRO-ETH LP tokens to stake. ${makeAdditional()} ${makeExtraStake()}`;

  function makeApproveButtonLabel(): string {
    switch (stakingStage) {
      case StakingStage.INIT:
        return 'Approve Staking';
      case StakingStage.APPROVING:
        return 'Approving...';
      default:
        return 'APPROVED';
    }
  }

  function makeStakingButtonLabel(): string {
    switch (stakingStage) {
      case StakingStage.STAKING:
        return 'Staking';

      case StakingStage.COMPLETE:
        return 'Done';

      default:
        return 'Submit Staking';
    }
  }

  function handleInput(value: string) {
    let newValue = value;

    if (contains('.', newValue) && compose(last, split(''))(newValue) !== '0') {
      if (parseFloat(newValue)) newValue = kiroboRound(newValue);
    }

    setQuantity(newValue);
  }

  return (
    <>
      <div style={{ height: '6.8rem' }}>
        <Instructions instruction={makeInstruction()} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          margin: ifTrue(isMobile, '1rem 0', '2rem 0'),
        }}
      >
        <Balance align={ifTrue(isMobile, 'left')} label='Your Pool Balance' amount={makeAmount(totalBalance)} />
        <Balance
          align={ifTrue(isMobile, 'right')}
          label='Staked (out of the Pool Balance)'
          amount={makeAmount(stakedBalance)}
        />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Input
          withMax
          value={quantity}
          max={new BN(toWei(kiroboRound(stakeLimitNorm)))}
          onChange={handleInput}
          disabled={pending?.status === 'pending' || inputDisabled}
          maxButtonDisabled={pending?.status === 'pending'}
        />
      </div>
      {ifTrueFn(!noErrors, renderErrors)}
      <FlexSpacer max='100%' min='1rem' />
      <div style={{ display: 'flex', flexFlow: 'row' }}>
        <ActionButton
          kirobo
          disabled={approveButtonIsDisabled || pending?.status === 'pending'}
          label={makeApproveButtonLabel()}
          onClick={handleApproveClick}
        />
        <Spacer horizontal={2} />
        <ActionButton
          disabled={stakingButtonIsDisabled || pending?.status === 'pending'}
          label={makeStakingButtonLabel()}
          onClick={handleStakeClick}
        />
      </div>
    </>
  );
}
