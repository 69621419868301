const Config = {
  unipoolRinkeby: '0xB7265c28ae376Bf20Fc998eb85A1B75d564B9DaB',
  newUnipoolMainnet: '0x98c6B8F0AE66833C7afCf71533576685aCFa0c8f',
  unipoolMainnet: '0x50E49A438267218a3FEcC3754d7b6dFA0C492AC1',
  newStripText: [
    'This is the old version of the staking which was replaced on the 3rd December 2020. For the new version click ',
    'here',
    '',
  ],
  newStripLink: 'https://staking.kirobo.me/new/',
};

export default Config;
