import BN from 'bn.js';
import { assoc, assocPath } from 'ramda';

import { MappedReducerFunctions, AnyAction, State, Actions } from 'src/models';
import { INITIAL_STATE } from './initial';

const MAP: MappedReducerFunctions = new Map();

MAP.set(Actions.CONNECT, (state: State, action: AnyAction) => assoc('isAuth', action.payload, state));
MAP.set(Actions.AUTHED, (state: State, action: AnyAction) => assoc('isAuth', action.payload, state));
MAP.set(Actions.SET_SELECTED_POOL, (state: State, action: AnyAction) => assoc('selectedPool', action.payload, state));
MAP.set(Actions.SET_LP_TOKEN_CONTRACT, (state: State, action: AnyAction) =>
  assoc('lpTokenContract', action.payload, state),
);
MAP.set(Actions.SET_UNI_POOL_CONTRACT, (state: State, action: AnyAction) =>
  assoc('uniPoolContract', action.payload, state),
);
MAP.set(Actions.SET_REWARD_BALANCE, (state: State, action: AnyAction) => assoc('rewardBalance', action.payload, state));
MAP.set(Actions.SET_STAKED_BALANCE, (state: State, action: AnyAction) => assoc('stakedBalance', action.payload, state));
MAP.set(Actions.SET_TOKEN_BALANCE, (state: State, action: AnyAction) => assoc('balance', action.payload, state));
MAP.set(Actions.SET_SIGNER, (state: State, action: AnyAction) => assoc('signer', action.payload, state));
MAP.set(Actions.SET_PENDING_TX, (state: State, action: AnyAction) => assoc('pending', action.payload, state));
MAP.set(Actions.SET_PENDING_TX_STATUS, (state: State, action: AnyAction) =>
  assocPath(['pending', 'status'], action.payload, state),
);
MAP.set(Actions.WIPE_STATE, () => INITIAL_STATE);
MAP.set(Actions.SET_MAX_ALLOWED, (state: State, action: AnyAction) => assoc('maxAllowed', action.payload, state));
MAP.set(Actions.SET_PENDING_TX, (state: State, action: AnyAction) => assoc('pending', action.payload, state));
MAP.set(Actions.SET_PENDING_TX_STATUS, (state: State, action: AnyAction) =>
  assocPath(['pending', 'status'], action.payload, state),
);
MAP.set(Actions.SET_STAKING_STAGE, (state: State, action: AnyAction) => assoc('stakingStage', action.payload, state));
MAP.set(Actions.CLEAR_CONTRACT_DATA, (state: State) => ({
  ...state,
  lpTokenAllowance: new BN(0),
  lpTokenContract: undefined,
  uniPoolContract: undefined,
  balance: new BN(0),
  rewardBalance: new BN(0),
  stakedBalance: new BN(0),
  maxAllowed: undefined,
  // TODO: anything else should be added?
}));
MAP.set(Actions.SET_APY, (state: State, action: AnyAction) => assoc('apy', action.payload * 0, state));
MAP.set(Actions.SET_WEEKLY_REWARD, (state: State, action: AnyAction) => assoc('weeklyReward', action.payload, state));
MAP.set(Actions.SET_SHOW_WARNING, (state: State, action: AnyAction) => assoc('showWarning', action.payload, state));

export { MAP };
