import { Popover } from 'element-react/next';
import React from 'react';
import { Footer } from '..';

import style from './Menu.module.scss';

export function Menu() {
  return (
    <Popover placement='top' width='180' trigger='click' popperClass={style.popper} content={<Footer />}>
      <button className={style.container}>
        <p className={style.label}>?</p>
      </button>
    </Popover>
  );
}
