import { map } from 'ramda';
import React from 'react';

import { DialogTabButton } from '..';
import { Tabs } from '../Tabs';

import { TABS } from 'src/data';
import style from './TabbedDialogMobile.module.scss';

export function TabbedDialogMobile() {
  const [currentTab, setCurrentTab] = React.useState<string>('Stake');

  const handleTabSwitch = (tab: string) => () => setCurrentTab(tab);

  const tabButtonsMapper = (tab: string) => (
    <DialogTabButton key={tab} tab={tab} isSelected={currentTab === tab} onClick={handleTabSwitch(tab)} />
  );

  return (
    <div className={style.container}>
      <header className={style.header}>{map(tabButtonsMapper, TABS)}</header>
      <div className={style.content}>
        <Tabs dialogMode tab={currentTab} />
      </div>
    </div>
  );
}
