import clsx from 'clsx';
import React from 'react';
import { IconClose } from 'src/assets';
import { useDispatch, useAppState, setShowWarning } from 'src/state';

import style from './WarningStrip.module.scss';

export function WarningStrip() {
  const dispatch = useDispatch();

  const { showWarning } = useAppState();

  const turnOffWarning = () => dispatch(setShowWarning(false));

  if (!showWarning) return <></>;

  return (
    <div className={clsx(style.container, 'animate__animated animate__fadeIn')}>
      <p className={style.message}>
        This app is in beta, you might lose your funds. Use at your own risk. Click{' '}
        <a
          href='https://github.com/g0-group/Audits/blob/master/StakingKiroboSep2020.pdf'
          target='_blank'
          rel='noopener noreferrer'
          className={style.link}
        >
          here
        </a>{' '}
        for Audit report. US and Israeli Citizens are strictly prohibited from participating in The Staking Program.
      </p>
      <button className={style.icon} onClick={turnOffWarning}>
        <IconClose />
      </button>
    </div>
  );
}
