import clsx from 'clsx';
import { map } from 'ramda';
import React from 'react';

import { useMedia } from 'src/hooks';
import { ifTrue, ifTrueAlt, ifTrueAltFn } from 'src/toolbelt';

import { LogoDiscord, LogoTelegram } from 'src/assets';
import { LINKS } from 'src/data';

import style from './Footer.module.scss';

export function Footer() {
  const isMobile = useMedia();

  const mapperFn = (el: Record<string, string>) => (
    <a key={el.link} href={el.link} target='_blank' rel='noopener noreferrer' className={style.link}>
      <p className={style.label}>{el.label}</p>
      <span className={style.underline} />
    </a>
  );

  const renderButtons = () => (
    <>
      <a
        className={ifTrue(isMobile, style.link, style.icon)}
        title='Discord'
        href='https://discord.gg/2NkvKrc'
        target='_blank'
        rel='noopener noreferrer'
      >
        {ifTrueAlt(isMobile, <p className={style.label}>Discord</p>, <LogoDiscord />)}
      </a>
      <a
        className={ifTrue(isMobile, style.link, style.icon)}
        title='Telegram'
        href='https://t.me/kirobo'
        target='_blank'
        rel='noopener noreferrer'
      >
        {ifTrueAlt(isMobile, <p className={style.label}>Telegram</p>, <LogoTelegram />)}
      </a>
    </>
  );

  const renderButtonsInRow = () => renderButtons();

  return (
    <footer className={clsx(style.container, ifTrueAlt(isMobile, style.mobile, style.desktop))}>
      {map(mapperFn, LINKS)}
      {ifTrueAltFn(isMobile, renderButtonsInRow, renderButtons)}
    </footer>
  );
}
