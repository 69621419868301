import clsx from 'clsx';
import React from 'react';

import { useMedia } from 'src/hooks';
import { ifTrue, ifTrueAlt, ifTrueAltFn, ifTrueFn } from 'src/toolbelt';

import { useAppState } from 'src/state';

import { Spacer, TabbedDialogMobile, TabbedDialog, TransactionCard, TransactionCardMobile } from 'src/components';

import style from './MainPage.module.scss';

export function MainPage() {
  const isMobile = useMedia();

  const { pending } = useAppState();

  // const pendingTransaction = useSelector(getPendingTransaction);

  const renderTransactionCardDesktop = () => <TransactionCard />;

  const renderTransactionCardMobile = () => <TransactionCardMobile />;

  const renderTransactionCard = () => ifTrueAltFn(isMobile, renderTransactionCardMobile, renderTransactionCardDesktop);

  const renderDesktop = () => <TabbedDialog />;

  const renderMobile = () => <TabbedDialogMobile />;

  return (
    <div
      className={clsx(
        style.container,
        ifTrue(isMobile, style['container-mobile']),
        'animate__animated animate__fadeIn',
      )}
      style={ifTrue(isMobile, { width: '90%', margin: '0 auto' })}
    >
      {ifTrueAltFn(isMobile, renderMobile, renderDesktop)}
      <Spacer vertical={ifTrueAlt(isMobile, 1, 2)} />
      {ifTrueFn(!!pending, renderTransactionCard)}
    </div>
  );
}
