import clsx from 'clsx';
import React from 'react';
import { useMedia } from 'src/hooks';
import { ifTrue } from 'src/toolbelt';

import style from './Instructions.module.scss';

interface InstructionsProps {
  instruction: string;
}

export function Instructions({ instruction }: InstructionsProps) {
  const isMobile = useMedia();

  return (
    <div className={clsx(style.container, ifTrue(isMobile, style.mobile, style.desktop))}>
      <img className={style.icon} src='/images/image_info.png' alt='Info' />
      <p className={style.instructions}>{instruction}</p>
    </div>
  );
}
