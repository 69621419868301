import BN from 'bn.js';
import {
  Actions,
  AnyAction,
  Signer,
  StakingPool,
  TokenContract,
  PendingTransaction,
  TransactionStatus,
  StakingStage,
} from 'src/models';
import { Maybe } from 'src/toolbelt';

const appIsAuthed = (payload = false): AnyAction => ({ type: Actions.AUTHED, payload });

const setSelectedPool = (payload?: StakingPool): AnyAction => ({
  type: Actions.SET_SELECTED_POOL,
  payload,
});

const setLpTokenContract = (payload: Maybe<TokenContract>): AnyAction => ({
  type: Actions.SET_LP_TOKEN_CONTRACT,
  payload,
});

const setUniPoolContract = (payload: Maybe<TokenContract>): AnyAction => ({
  type: Actions.SET_UNI_POOL_CONTRACT,
  payload,
});

const setRewardBalance = (payload: BN): AnyAction => ({
  type: Actions.SET_REWARD_BALANCE,
  payload,
});

const setStakedBalance = (payload: BN): AnyAction => ({
  type: Actions.SET_STAKED_BALANCE,
  payload,
});

const setTokenBalance = (payload: BN): AnyAction => ({
  type: Actions.SET_TOKEN_BALANCE,
  payload,
});

const setMaxAllowed = (payload: BN): AnyAction => ({
  type: Actions.SET_MAX_ALLOWED,
  payload,
});

const setSigner = (payload: Maybe<Signer>): AnyAction => ({
  type: Actions.SET_SIGNER,
  payload,
});

const setPendingTx = (payload: Maybe<PendingTransaction>): AnyAction => ({
  type: Actions.SET_PENDING_TX,
  payload,
});

const setPendingTxStatus = (payload: TransactionStatus): AnyAction => ({
  type: Actions.SET_PENDING_TX_STATUS,
  payload,
});

const setStakingStage = (payload: StakingStage): AnyAction => ({
  type: Actions.SET_STAKING_STAGE,
  payload,
});

const setAPY = (payload: number): AnyAction => ({
  type: Actions.SET_APY,
  payload,
});

const setWeeklyReward = (payload: number): AnyAction => ({
  type: Actions.SET_WEEKLY_REWARD,
  payload,
});

const wipeState = (): AnyAction => ({
  type: Actions.WIPE_STATE,
});

const clearContractData = (): AnyAction => ({
  type: Actions.CLEAR_CONTRACT_DATA,
});

const clearErrors = (): AnyAction => ({
  type: Actions.CLEAR_ERRORS,
});

const addError = (payload: string): AnyAction => ({
  type: Actions.ADD_ERROR,
  payload,
});

const setShowWarning = (payload: boolean): AnyAction => ({
  type: Actions.SET_SHOW_WARNING,
  payload,
});

export {
  appIsAuthed,
  clearContractData,
  setLpTokenContract,
  setMaxAllowed,
  setPendingTx,
  setPendingTxStatus,
  setRewardBalance,
  setSelectedPool,
  setSigner,
  setStakedBalance,
  setStakingStage,
  setTokenBalance,
  setUniPoolContract,
  wipeState,
  setWeeklyReward,
  setAPY,
  clearErrors,
  addError,
  setShowWarning,
};
