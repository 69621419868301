import clsx from 'clsx';
import React from 'react';
import VanillaTilt from 'vanilla-tilt';

import { ifTrue } from 'src/toolbelt';

import style from './CardButton.module.scss';

interface Props {
  onClick: () => void;
  token?: string;
  content?: JSX.Element;
  disabled?: boolean;
}

export function CardButton({ onClick, content, disabled, token }: Props) {
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    if (buttonRef.current) {
      VanillaTilt.init(buttonRef.current, { gyroscope: true });
    }
  }, [buttonRef]);

  const handleOnClick = () => onClick();

  const tokenInfo = () => (
    <div className={style.content}>
      <h1 className={clsx(style.title)}>
        <span className={style.kirobo}>KIRO</span> - {`${token}`}
      </h1>
    </div>
  );

  const renderContent = () => (content ? content : tokenInfo());

  return (
    <button
      ref={buttonRef}
      disabled={disabled}
      onClick={handleOnClick}
      className={clsx(
        style.wrapper,
        ifTrue(!Boolean(content), 'animate__animated animate__pulse'),
        ifTrue(Boolean(!onClick || disabled), style.disabled),
        ifTrue(!disabled, style.border),
      )}
    >
      <div className={style.overlay} />
      <div className={style.container}>{renderContent()}</div>
    </button>
  );
}
