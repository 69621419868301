import clsx from 'clsx';
import React from 'react';
import { fromWei } from 'web3-utils';

import { makeStringAmount } from 'src/toolbelt';

import { useAppState } from 'src/state';

import { STATUS_COLORS } from 'src/data';
import style from './TransactionCardMobile.module.scss';

export function TransactionCardMobile() {
  const transaction = useAppState().pending;

  if (!transaction) return <></>;

  return (
    <div id='transaction_card_mobile' className={clsx(style.container, 'animate__animated animate__fadeIn')}>
      <div className={style.section}>
        <p>
          Transaction hash <span>(click to explore)</span>
        </p>
        <a
          href={transaction.explorerUrl}
          target='_blank'
          rel='noopener noreferrer'
          className={clsx(style.hash, 'animate__animated animate__fadeIn')}
        >
          {transaction.hash}
        </a>
      </div>

      <div className={style.row}>
        <div className={style['block-left']}>
          <p className={style.name}>Transaction Type</p>
          <p className={style.value}>{transaction.txType}</p>
        </div>

        <div className={style['block-right']}>
          <p className={style.name}>{transaction.token}</p>
          <p className={style.value}>
            {makeStringAmount({ min: 6, max: 6 })(parseFloat(fromWei(transaction.value.toString())))}
          </p>
        </div>
        <div className={style['status-block']} style={{ borderColor: STATUS_COLORS[transaction.status] }}>
          <p className={style['status-label']}>STATUS</p>
          <p className={style.status} style={{ color: STATUS_COLORS[transaction.status] }}>
            {transaction.status}
          </p>
        </div>
      </div>
    </div>
  );
}
