import { useEffect, useState } from 'react';

const getWidth = (widthPx: number) => window.matchMedia(`(max-width: ${widthPx}px)`).matches;

function useMedia(widthPx = 450) {
  const [current, setCurrent] = useState(getWidth(widthPx));

  useEffect(() => {
    globalThis.addEventListener('resize', () => {
      setCurrent(getWidth(widthPx));
    });

    return () => {
      globalThis.removeEventListener('resize', () => {});
    };
  }, [widthPx]);

  return current;
}

export { useMedia };
