import React from 'react';
import Blockies from 'react-blockies';

import style from './Avatar.module.scss';

const PX_RATIO = typeof devicePixelRatio === 'undefined' ? 2 : devicePixelRatio;

const BLOCKIES_SQUARES = 8; // commonly used to represent Ethereum addresses

const BASE_SCALE = 3;

interface Props {
  address: string;
  scale?: number;
  radius?: number;
  soften?: number;
}

export function Avatar({ address, scale = 1, radius = 0, soften = 0.3 }: Props) {
  const blockiesScale = scale * BASE_SCALE;

  const makeMainStyles = () => ({
    width: `${BLOCKIES_SQUARES * blockiesScale}px`,
    height: `${BLOCKIES_SQUARES * blockiesScale}px`,
    borderRadius: `${radius}px`,
  });

  const makeOpacityStyles = () => ({
    opacity: `${soften}px`,
  });

  const makeScalingStyles = () => ({
    width: `${BLOCKIES_SQUARES * blockiesScale * PX_RATIO}px`,
    height: `${BLOCKIES_SQUARES * blockiesScale * PX_RATIO}px`,
    // transform: `scale(${1 / PX_RATIO}, ${1 / PX_RATIO})`,
  });

  return (
    <div id='avatar' className={style.container} style={makeMainStyles()}>
      <div style={makeScalingStyles()}>
        <div style={makeOpacityStyles()}>
          <Blockies seed={address.toLowerCase()} size={BLOCKIES_SQUARES} scale={blockiesScale * PX_RATIO} />
        </div>
      </div>
    </div>
  );
}
