import { StakingPool, State } from 'src/models';
import { Maybe } from 'src/toolbelt';

const getSelectedPool = (state: State): Maybe<StakingPool> => state.selectedPool;

const getIsAuth = (state: State) => state.isAuth;

const getIsTitlePage = (state: State) => !state.isAuth || !state.selectedPool;

const getUniPoolContract = (state: State) => state.uniPoolContract;

const getLpTokenContract = (state: State) => state.lpTokenContract;

const getSigner = (state: State) => state.signer;

const getPendingTx = (state: State) => state.pending;

export { getIsAuth, getIsTitlePage, getSelectedPool, getUniPoolContract, getLpTokenContract, getSigner, getPendingTx };
