import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { useMedia } from 'src/hooks';
import { ifTrue } from 'src/toolbelt';

import style from './Balance.module.scss';

interface Props {
  label?: string;
  amount: string;
  align?: 'left' | 'right';
  labelStyles?: CSSProperties;
  numberStyles?: CSSProperties;
}

export function Balance({ label, amount, align, numberStyles, labelStyles }: Props) {
  const isMobile = useMedia();

  return (
    <div className={clsx(style.container, ifTrue(isMobile, style.mobile), ifTrue(!!align, style[align as string]))}>
      <h4
        className={style.label}
        style={{
          ...ifTrue(!!align, {
            textAlign: align as 'left' | 'right',
          }),
          ...labelStyles,
        }}
      >
        {label || 'Current balance'}
      </h4>
      <p className={clsx(style.amount, 'animate__animated animate__fadeIn')} style={numberStyles}>
        {amount}
      </p>
    </div>
  );
}
