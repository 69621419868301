import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { ViewportProvider } from 'use-viewport';
import { useSpring, animated } from 'react-spring';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

import { connectEthProvider, ifTrue, ifTrueAlt, ifTrueAltFn, ifTrueFn } from 'src/toolbelt';
import { useMedia } from 'src/hooks';

import { useSelector } from 'src/state';
import { getIsTitlePage } from 'src/state/getters';

import {
  AccountMobile,
  BackButton,
  KiroboServiceButton,
  Menu,
  Middleware,
  Navbar,
  WarningStrip,
  WrongNetwork,
  YellowStrip,
} from 'src/components';
import { MainPage, TitlePage } from 'src/pages';

import style from './App.module.scss';

function App() {
  const isMobile = useMedia();

  const isTitlePage = useSelector(getIsTitlePage);

  const revealProps = useSpring({
    from: { opacity: 0, transform: 'scale3d(0.98, 0.98, 1)' },
    to: { opacity: 1, transform: 'scale3d(1, 1, 1)' },
  });

  const renderPage = () => <MainPage />;

  const renderTitlePage = () => <TitlePage />;

  const renderBackButton = () => <BackButton />;

  const renderAccountMobile = () => <AccountMobile />;

  const shouldRenderKiroboButton = () => ifTrueAlt(isMobile, false, true);

  const insertGaScript = () => (
    <Helmet>
      <script async src='https://www.googletagmanager.com/gtag/js?id=UA-179088683-1'></script>
      <script>{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-179088683-1');`}</script>
    </Helmet>
  );

  return (
    <Web3ReactProvider getLibrary={connectEthProvider}>
      <ViewportProvider>
        <animated.div style={revealProps}>
          <Middleware />
          {ifTrueFn(process.env.NODE_ENV === 'production', insertGaScript)}
          <div
            className={clsx(style.container)}
            style={{
              backgroundColor: ifTrueAlt(isTitlePage, '#fff', '#f5f5f5'),
            }}
          >
            <WrongNetwork />
            <Navbar />
            {ifTrueFn(isMobile, renderAccountMobile)}
            {ifTrueFn(!isMobile && !isTitlePage, renderBackButton)}
            {ifTrueAltFn(isTitlePage, renderTitlePage, renderPage)}
            {ifTrue(shouldRenderKiroboButton(), <KiroboServiceButton />)}
            <Menu />
            {!isTitlePage && <YellowStrip />}
            <WarningStrip />
          </div>
        </animated.div>
      </ViewportProvider>
    </Web3ReactProvider>
  );
}

export default App;
