import React from 'react';
import { useAppState } from 'src/state';
import { logInfo } from 'src/toolbelt';

function useLogger() {
  const state = useAppState();

  React.useEffect(() => {
    logInfo('State updated', state);
  }, [state]);
}

export { useLogger };
