import clsx from 'clsx';
import React from 'react';

import { useWeb3React } from '@web3-react/core';
import { IconNetwork } from 'src/assets';

import style from './WrongNetwork.module.scss';

export function WrongNetwork() {
  const chainId = useWeb3React().chainId;

  const isDisabled = chainId && chainId !== 1 && chainId !== 4;

  const [showWarning, setShowWarning] = React.useState(false);

  React.useEffect(() => {
    if (!isDisabled && showWarning) setShowWarning(false);
    else if (isDisabled && !showWarning) setShowWarning(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  if (!showWarning) return <></>;

  return (
    <div className={clsx(style.container, 'animate__animated animate__zoomIn')}>
      <span className={style.icon}>
        <IconNetwork />
      </span>
      <p className={style.message}>Wrong network. Please, use either Main or Rinkeby Networks</p>
    </div>
  );
}
