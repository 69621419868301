import { useWeb3React } from '@web3-react/core';
import React from 'react';
import * as ethers from 'ethers';

import { useBalances } from './useBalances';

import { clearContractData, setLpTokenContract, setUniPoolContract, useAppState, useDispatch } from 'src/state';

import UNIPOOL_ABI from 'src/constants/unipool.abi';
import ERC20_ABI from 'src/constants/kiro.abi';
import { MAINNET_POOLS, RINKEBY_POOLS } from 'src/constants';

const usePools = () => {
  const { getAllBalances } = useBalances();

  const { selectedPool } = useAppState();

  const dispatch = useDispatch();

  const web3 = useWeb3React();

  const pools = useWeb3React().chainId === 1 ? MAINNET_POOLS : RINKEBY_POOLS;

  React.useEffect(() => {
    // TODO: check if works as generic
    if (web3.library && selectedPool) {
      // TODO: assign to context
      // create contracts and store them in state for later use

      const lpToken = selectedPool
        ? new ethers.Contract(selectedPool.lpTokenAddress, ERC20_ABI, web3.library.getSigner())
        : new ethers.Contract(pools[0].lpTokenAddress, ERC20_ABI, web3.library.getSigner());

      const uniPool = selectedPool
        ? new ethers.Contract(selectedPool.unipoolAddress, UNIPOOL_ABI, web3.library.getSigner())
        : new ethers.Contract(pools[0].unipoolAddress, UNIPOOL_ABI, web3.library.getSigner());

      dispatch(setLpTokenContract(lpToken));

      dispatch(setUniPoolContract(uniPool));

      // subscribe to 'new block' event
      // web3.library.on('block', async () => {
      //   await getAllBalances(uniPool, lpToken);
      // });
    } else if (!selectedPool) {
      // clear the pool data, but not signer
      dispatch(clearContractData());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPool]);
};

export { usePools };
