import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Dialog } from 'element-react/next';

import { ifTrue, ifTrueAlt, ifTrueFn } from 'src/toolbelt';

import { Account } from '../Account';
import { MainButton } from '..';
import { WalletSelector } from '../WalletSelector';

export function AccountSelector() {
  const { account, connector } = useWeb3React();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleModalOpen = (state = false) => () => {
    // @ts-ignore
    connector?.walletConnectProvider?.close();
    setIsOpen(state);
  };

  const renderAccount = () => <Account account={account as string} />;

  const renderConnectButton = () => (
    <MainButton
      small
      label={ifTrueAlt(!!account, 'Change Wallet', 'Connect')}
      onClick={handleModalOpen(true)}
      styles={ifTrue(!!account, { background: 'var(--grey)' })}
    />
  );

  return (
    <>
      {renderConnectButton()}
      {ifTrueFn(!!account, renderAccount)}
      <Dialog size='small' visible={isOpen} onCancel={handleModalOpen()} showClose={false}>
        <Dialog.Body>
          <WalletSelector onClose={handleModalOpen()} />
        </Dialog.Body>
      </Dialog>
    </>
  );
}
