import { ChainId, Token, WETH, Fetcher, Route } from '@uniswap/sdk';
import { KIRO, MAINNET_POOLS } from '../constants/addresses';
import UNIPOOL_ABI from '../constants/unipool.abi';
import axios from 'axios';
import utils, { fromWei } from 'web3-utils';
import * as ethers from 'ethers';
import BN from 'bn.js';

export async function getEthPrice() {
  try {
    const ethPriceResponse = await axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd',
    );

    const ethPrice = parseFloat(ethPriceResponse.data.ethereum.usd);

    return ethPrice;
  } catch (err) {
    // console.log(`Error getting ETH price ${err}`);
    return 0;
  }
}

export async function getKiroPrice(chainId: number) {
  try {
    const chain = chainId === 1 ? ChainId.MAINNET : ChainId.RINKEBY;

    const kiroAddress = chainId === 1 ? KIRO.mainnet : KIRO.rinkeby;

    const kiro = new Token(chain, kiroAddress, 18);

    // note that you may want/need to handle this async code differently,
    // for example if top-level await is not an option
    const pair = await Fetcher.fetchPairData(kiro, WETH[chain]);

    const route = new Route([pair], WETH[chain]);

    const kiroPriceInETH = parseFloat(route.midPrice.invert().toSignificant(6));

    const ethPrice = await getEthPrice();

    const kiroPriceInUSD = kiroPriceInETH * ethPrice;

    return kiroPriceInUSD;
  } catch (err) {
    // console.log(`Error getting KIRO price: ${err}`);
    return 0;
  }
}

export async function getKiroPriceEth(poolContract: ethers.Contract) {
  const [kiroReserve, ethReserve] = await poolContract.pairInfo();

  const kiroReserveBN = new BN(kiroReserve.toString());

  const ethReserveBN = new BN(ethReserve.toString());

  const kiroInEth = parseFloat(utils.fromWei(ethReserveBN)) / parseFloat(utils.fromWei(kiroReserveBN));

  return kiroInEth;
}

export async function getLPTokenValue(chainId: number, ethAmount: BN, kiroAmount: BN, totalSupply: BN) {
  try {
    const ethPrice = await getEthPrice();

    const kiroPrice = await getKiroPrice(chainId);

    const ethTotalValue = parseFloat(utils.fromWei(ethAmount, 'ether')) * ethPrice;

    const kiroTotalValue = parseFloat(utils.fromWei(kiroAmount, 'ether')) * kiroPrice;

    const value = (ethTotalValue + kiroTotalValue) / parseFloat(utils.fromWei(totalSupply, 'ether'));

    return value;
  } catch (err) {
    // console.log(`Error calculating lp token value ${err}`);
    return 0;
  }
}

export async function getGenericAPY() {
  try {
    const provider = new ethers.providers.InfuraProvider('homestead', 'eb8762a055254f4c84dbd20977104169');

    const mainUnipool = new ethers.Contract(MAINNET_POOLS[0].unipoolAddress, UNIPOOL_ABI, provider);

    let totalSupplyStaked = new BN((await mainUnipool.totalSupply()).toString());

    const rewardRate = new BN((await mainUnipool.rewardRate()).toString());

    const maxAllowed = new BN((await mainUnipool.pairLimit(mainUnipool.address)).toString());

    const maxStakingWeight = parseFloat(fromWei(maxAllowed)) / parseFloat(fromWei(totalSupplyStaked));

    const kiroYearlyReward = parseFloat(fromWei(rewardRate.muln(60 * 60 * 24 * 365)));

    const stakingLimit = await mainUnipool.stakingLimit();

    const stakingLimitNorm = parseFloat(fromWei(new BN(stakingLimit.toString())));

    const kiroEthPrice = await getKiroPriceEth(mainUnipool);

    const kiroStakingLimit = stakingLimitNorm / kiroEthPrice;

    const maxApyKiro = (kiroYearlyReward / kiroStakingLimit) * maxStakingWeight;

    return maxApyKiro;
  } catch (err) {
    console.log(`err calculating general APY`);
    return 0;
  }
}
