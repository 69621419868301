import BN from 'bn.js';
import React from 'react';
import clsx from 'clsx';
import { fromWei } from 'web3-utils';
import { compose } from 'ramda';

import { useMedia } from 'src/hooks';
import { decimalFromWei, ifTrue, ifTrueAlt, kiroboRound } from 'src/toolbelt';
import { addError, clearErrors, useDispatch } from 'src/state';

import { MaxButton } from '../MaxButton';

import style from './Input.module.scss';

interface Props {
  withMax?: boolean;
  value: string;
  max: BN;
  placeholder?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  maxButtonDisabled: boolean;
}

export function Input({ placeholder, withMax, value, max, onChange, disabled, maxButtonDisabled }: Props) {
  const dispatch = useDispatch();

  const isMobile = useMedia();

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();

  const isMaxButtonDisabled = () => parseFloat(value) === decimalFromWei(max.toString()) || maxButtonDisabled;

  const handleMaxOnClick = () => onChange(fromWei(max));

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(clearErrors());
    let newValue = e.target.value;

    if (parseFloat(newValue) < 0) {
      dispatch(addError('Wrong value'));
      return;
    }

    if (parseFloat(newValue) > compose(Number, kiroboRound, decimalFromWei)(max.toString())) {
      dispatch(addError('You cannot exceed available quantity'));
      return;
    }

    onChange(newValue);
  }

  return (
    <div className={style.wrapper}>
      <input
        autoFocus
        className={clsx(style.container, ifTrueAlt(isMobile, style.mobile, style.desktop))}
        type='number'
        placeholder={placeholder || 'Enter quantity...'}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        value={value}
        disabled={disabled}
      />
      {ifTrue(!!withMax, <MaxButton disabled={isMaxButtonDisabled()} onClick={handleMaxOnClick} />)}
    </div>
  );
}
