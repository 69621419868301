import { useWeb3React } from '@web3-react/core';
import React from 'react';

import { sendWarning } from 'src/toolbelt';

function useNotifications() {
  const error = useWeb3React().error?.message;

  const [cachedError, setCachedError] = React.useState('');

  React.useEffect(() => {
    if (error && error !== cachedError) {
      sendWarning('Attention', error);
      setCachedError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
}

export { useNotifications };
