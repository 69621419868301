import clsx from 'clsx';
import React from 'react';
import { fromWei } from 'web3-utils';

import { ifTrueFn, makeStringAmount } from 'src/toolbelt';

import { useAppState } from 'src/state';

import { STATUS_COLORS } from 'src/data';
import style from './TransactionCard.module.scss';

export function TransactionCard() {
  const transaction = useAppState().pending;

  if (!transaction) return <></>;

  const renderPending = () => <img src='/images/animation_pending.gif' alt='Pending...' />;

  return (
    <div className={clsx(style.container, 'animate__animated animate__fadeIn')}>
      <div className={style.corner}>
        <p>MetasMask Wallet</p>
      </div>
      <div className={style.content}>
        <div className={style.section}>
          <p>
            Transaction hash <span>(click to explore)</span>
          </p>
          <a
            href={transaction.explorerUrl}
            target='_blank'
            rel='noopener noreferrer'
            className={clsx(style.hash, 'animate__animated animate__fadeIn')}
          >
            {transaction.hash}
          </a>
        </div>
        <div className={style['value-block']}>
          <div className={style.oval}>
            <p>{transaction.txType}</p>
          </div>
          <div className={style.value}>
            <p className={style['token-name']}>{transaction.token}</p>
            <p>{makeStringAmount({ min: 6, max: 6 })(parseFloat(fromWei(transaction.value.toString())))}</p>
          </div>
        </div>
      </div>
      <div className={style.divider} />
      <div className={style.right}>
        <p className={style['status-label']}>STATUS</p>
        <p className={style.status} style={{ color: STATUS_COLORS[transaction.status] }}>
          {transaction.status}
        </p>
        {ifTrueFn(transaction.status === 'pending', renderPending)}
      </div>
    </div>
  );
}
