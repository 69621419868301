import React from 'react';
import clsx from 'clsx';
import { ifTrue } from 'src/toolbelt';

import style from './DialogTabButton.module.scss';

interface Props {
  tab: string;
  isSelected: boolean;
  onClick: () => void;
}

export function DialogTabButton({ tab, isSelected, onClick }: Props) {
  const handleOnClick = () => onClick();

  return (
    <button className={clsx(style.container, ifTrue(isSelected, style.selected))} onClick={handleOnClick}>
      <div className={style['label-container']}>
        <p className={style.label}>{tab}</p>
        <span className={style.underline} />
      </div>
    </button>
  );
}
