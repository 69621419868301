import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import BN from 'bn.js';
import {
  setRewardBalance,
  setStakedBalance,
  setTokenBalance,
  setMaxAllowed,
  setWeeklyReward,
  useDispatch,
  setAPY,
  useAppState,
} from 'src/state';
import { getKiroPriceEth } from 'src/toolbelt/apy';

import { isAddress, fromWei } from 'web3-utils';
import { Contract } from 'ethers';

const useBalances = () => {
  const { account, library } = useWeb3React();

  const { lpTokenContract, uniPoolContract } = useAppState();

  const blockNumber = library?.blockNumber;

  const [prevBlock, setPrevBlock] = useState(blockNumber);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(`is new block ${blockNumber}`);

    if (blockNumber !== prevBlock && uniPoolContract && lpTokenContract) {
      getAllBalances(uniPoolContract, lpTokenContract);
      setPrevBlock(blockNumber);
    }
  }, [blockNumber]);

  async function getStakedBalance(uniPool: Contract) {
    try {
      if (isAddress(String(account)) && uniPool) {
        const stakedBalance = await uniPool.balanceOf(account);

        dispatch(setStakedBalance(new BN(stakedBalance.toString())));
      } else dispatch(setStakedBalance(new BN(0)));
    } catch (err) {
      // console.log(`Error getting staked balance: ${err}`);
      dispatch(setStakedBalance(new BN(0)));
    }
  }

  async function getRewardBalance(uniPool: Contract) {
    try {
      if (isAddress(String(account)) && uniPool) {
        const rewardBalance = await uniPool.earned(account);

        dispatch(setRewardBalance(new BN(rewardBalance.toString())));
      } else dispatch(setRewardBalance(new BN(0)));
    } catch (err) {
      // console.log(`Error getting reward balance: ${err}`);
      dispatch(setRewardBalance(new BN(0)));
    }
  }

  async function getTokenBalance(lpToken: Contract) {
    try {
      if (isAddress(String(account)) && lpToken) {
        const balance = await lpToken.balanceOf(account);

        dispatch(setTokenBalance(new BN(balance.toString())));
      } else {
        dispatch(setTokenBalance(new BN(0)));
      }
    } catch (err) {
      // console.log(`Error getting balance: ${err}`);
      dispatch(setTokenBalance(new BN(0)));
    }
  }

  async function getMaxAllowed(uniPool: Contract) {
    try {
      if (isAddress(String(account)) && uniPool) {
        const balance = await uniPool.pairLimit(account);

        dispatch(setMaxAllowed(new BN(balance.toString())));
      } else {
        dispatch(setMaxAllowed(new BN(0)));
      }
    } catch (err) {
      // console.log(`Error getting balance: ${err}`);
      dispatch(setMaxAllowed(new BN(0)));
    }
  }

  async function getWeeklyReward(uniPool: Contract) {
    if (uniPool)
      try {
        let totalSupplyStaked = new BN((await uniPool.totalSupply()).toString());

        const rewardRate = new BN((await uniPool.rewardRate()).toString());

        const userBalance = new BN((await uniPool.balanceOf(account)).toString());

        const kiroPerWeek = new BN(rewardRate)
          .mul(userBalance)
          .muln(60 * 60 * 24 * 7)
          .div(new BN(totalSupplyStaked));

        dispatch(setWeeklyReward(parseFloat(fromWei(kiroPerWeek))));
      } catch (err) {
        console.log('Error getting Kiro Weekly rewards');
        dispatch(setWeeklyReward(0));
      }
  }

  async function getApy(uniPool: Contract) {
    let totalSupplyStaked = new BN((await uniPool.totalSupply()).toString());

    const rewardRate = new BN((await uniPool.rewardRate()).toString());

    const maxAllowed = new BN((await uniPool.pairLimit(uniPool.address)).toString());

    const maxStakingWeight = parseFloat(fromWei(maxAllowed)) / parseFloat(fromWei(totalSupplyStaked));

    const kiroYearlyReward = parseFloat(fromWei(rewardRate.muln(60 * 60 * 24 * 365)));

    const stakingLimit = await uniPool.stakingLimit();

    const stakingLimitNorm = parseFloat(fromWei(new BN(stakingLimit.toString())));

    const kiroEthPrice = await getKiroPriceEth(uniPool);

    const kiroStakingLimit = stakingLimitNorm / kiroEthPrice;

    const maxApyKiro = (kiroYearlyReward / kiroStakingLimit) * maxStakingWeight;

    dispatch(setAPY(maxApyKiro));
  }

  async function getAllBalances(uniPool: Contract, lpToken: Contract) {
    await getTokenBalance(lpToken);
    await getRewardBalance(uniPool);
    await getStakedBalance(uniPool);
    await getMaxAllowed(uniPool);
    await getWeeklyReward(uniPool);
    await getApy(uniPool);
  }

  return { getRewardBalance, getStakedBalance, getAllBalances, getTokenBalance, getApy };
};

export { useBalances };
