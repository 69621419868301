import clsx from 'clsx';
import React from 'react';
import { useMedia } from 'src/hooks';
import { ifTrue } from 'src/toolbelt';

import style from './MaxButton.module.scss';

interface Props {
  disabled: boolean;
  onClick: () => void;
}

export function MaxButton({ disabled, onClick }: Props) {
  const isMobile = useMedia();

  function handleOnClick() {
    if (!disabled) onClick();
  }

  return (
    <button
      disabled={disabled}
      className={clsx(style.container, ifTrue(!isMobile, style.desktop, style.mobile))}
      onClick={handleOnClick}
    >
      MAX
    </button>
  );
}
